<template>
  <div class="sb_view_school_students-import">
    <sb-page-header>
      <template v-slot:left>
        <h1>{{ stbMode ? 'STB' : '' }} Leerlingen importeren</h1>
      </template>
    </sb-page-header>

    <sb-divider v-show="showUploadBox" title="Kies of sleep bestand" />

    <upload
      v-show="showUploadBox"
      :action="imporUrl"
      type="drag"
      :on-success="handleUploadSuccess"
      :on-error="handleUploadError"
      :on-format-error="handleUploadFormatError"
      :on-exceeded-size="handleUploadMaxSize"
      :before-upload="handleUploadBefore"
      :max-size="2048"
      :show-upload-list="false"
      :headers="headers"
    >
      <div style="padding: 60px 0">
        <icon type="ios-cloud-upload" size="52" style="color: #39f" />
        <p>
          Klik hier om een bestand te kiezen, of sleep het bestand in dit vak.
        </p>
      </div>
    </upload>

    <sb-spacer v-show="showUploadBox" :height="40" />

    <div v-if="errorMessageafterUpload">
      <sb-divider title="Er zijn validatie problemen met dit bestand" />

      <sb-call-out type="red">
        <template v-slot:left> ❗️ </template>
        <template v-slot:right>
          <p style="max-width: 800px" v-html="errorMessageafterUploadText"></p>
        </template>
      </sb-call-out>

      <sb-spacer height="10" />

      <sb-buttons-bar>
        <i-button
          type="primary"
          size="default"
          @click="
            (errorMessageafterUpload = false),
              (showUploadBox = true),
              (succesMessageafterUpload = false),
              (formatMessage = true)
          "
        >
          Probeer het nogmaals
        </i-button>
      </sb-buttons-bar>
    </div>

    <div v-if="succesMessageafterUpload">
      <sb-divider title="Het importeren is geslaagd" />

      <sb-call-out type="green">
        <template v-slot:left> 💪 </template>
        <template v-slot:right>
          <p style="max-width: 800px" v-html="succesMessageafterUploadText"></p>
        </template>
      </sb-call-out>

      <sb-spacer height="10" />

      <sb-buttons-bar>
        <router-link :to="{ name: 'ResourceManagementStudents' }">
          <i-button type="primary" size="default">
            Naar het Leerlingen overzicht
          </i-button>
        </router-link>
        <i-button type="secondary" size="default" @click="reset">
          Meer importeren
        </i-button>
      </sb-buttons-bar>
    </div>

    <sb-call-out v-if="errorMessage" type="red">
      <template v-slot:left> ❗️ </template>
      <template v-slot:right>
        <p style="max-width: 800px" v-html="errorMessageText"></p>
      </template>
    </sb-call-out>

    <sb-call-out v-if="formatMessage" type="default">
      <template v-slot:left> 👉 </template>
      <template v-slot:right>
        <p v-if="stbMode" style="max-width: 800px">
          Let op, het bestand moet aan de richtlijnen voldoen van de
          documentatie. We verwachten dat je hier een bestand uploadt dat vanuit
          <b>STB</b> is geëxporteerd in csv formaat.
        </p>
        <p v-else style="max-width: 800px">
          Let op, het bestand moet aan de richtlijnen voldoen van de
          documentatie. We verwachten dat je hier een bestand uploadt dat vanuit
          de template die je hieronder kunt downloaden is opgemaakt.
          <br />
          <br />
          <i-button type="primary" size="default" ghost>
            <a :href="imporUrl" target="_blank"> Download template</a>
          </i-button>
        </p>
      </template>
    </sb-call-out>

    <sb-spacer height="40" />
  </div>
</template>

<script>
import SbButtonsBar from '@/components/SbButtonsBar';
import SbCallOut from '@/components/SbCallOut';
import SbDivider from '@/components/SbDivider';
import SbPageHeader from '@/components/SbPageHeader';
import { getToken } from '@/lib/user-context-manager';

export default {
  components: {
    SbButtonsBar,
    SbCallOut,
    SbDivider,
    SbPageHeader,
  },
  data() {
    return {
      errorMessage: false,
      errorMessageText: '',
      formatMessage: true,
      showUploadBox: true,
      errorMessageafterUpload: false,
      errorMessageafterUploadText: '',
      succesMessageafterUpload: false,
      succesMessageafterUploadText: '',
    };
  },
  computed: {
    imporUrl() {
      return `${process.env.VUE_APP_GATEWAY_URL}/import`;
    },
    stbMode() {
      console.log(this.$route);
      return this.$route && this.$route.query && this.$route.query.stb;
    },
    headers() {
      return {
        authorization: `Bearer ${getToken()}`,
      };
    },
  },
  methods: {
    reset() {
      this.errorMessage = false;
      this.errorMessageText = '';
      this.formatMessage = true;
      this.showUploadBox = true;
      this.errorMessageafterUpload = false;
      this.errorMessageafterUploadText = '';
      this.succesMessageafterUpload = false;
      this.succesMessageafterUploadText = '';
    },
    handleUploadSuccess(res, file) {
      console.log('success', res.users.length, file);
      this.formatMessage = false;
      this.showUploadBox = false;
      this.succesMessageafterUpload = true;
      this.succesMessageafterUploadText = `Het is gelukt! Er zijn ${res.users.length} aantal leerlingen gevalideerd. <p> Het importeer proces begint nu. Je krijgt een e-mail wanneer dit voltooid is </p>`;
    },
    handleUploadFormatError(e) {
      console.log(e);
      this.errorMessage = true;
      this.errorMessageText =
        'Verkeerd format. Het bestand moet een .CSV bestand zijn.';
    },
    handleUploadError(error, res) {
      console.log({
        res,
      });
      this.errorMessageafterUpload = true;

      const header = `Foutmelding: ${res.error} <br>`;

      if (res.message === 'RegistrationNumber is not allowed in csv') {
        this.errorMessageafterUploadText =
          'Dit lijkt op een STB bestandstype. Een school mag dit niet importeren.';
        return;
      }
      if (res.message === 'Only CSV and XLSX files are allowed.') {
        this.errorMessageafterUploadText =
          'Dit is geen correct bestandstype. Alleen CSV an XLSX zijn toegestaan.';
        return;
      }

      function parseError(e) {
        if (typeof e === 'string') return `<p>${e}</p>`;
        return `<p>Import probleem voor gebruiker <b>${
          e.target.firstName || ''
        } ${e.target.prefix || ''} ${
          e.target.lastName || ''
        }</b> met nummer Inschrijvingscode <b>${
          e.target.registrationNumber
        }</b>:</p><li>${JSON.stringify(Object.values(e.constraints)[0])}</li>`;
      }

      const body = res.message
        .map((err) => {
          console.log(err);
          if (Array.isArray(err)) {
            return err.map(parseError);
          }
          return parseError(err);
        })
        .join('');
      this.errorMessageafterUploadText = `${header}<ul>${body}</ul>`;
    },
    handleUploadMaxSize() {},
    async handleUploadBefore(file) {
      console.log(file);

      // const { data } = await this.$apollo.mutate({
      //   mutation: MutationImportStb,
      //   variables: {
      //     file
      //   },
      //   context: {
      //     hasUpload: true
      //   }
      // });

      this.errorMessage = false;
      this.errorMessageText = '';
      this.errorMessageafterUpload = false;
      this.errorMessageafterUploadText = '';
    },
  },
};
</script>

<style lang="scss">
.sb_view_school_students-import {
  .sb_call-out {
    margin: 0 0 10px 0;
  }
}
</style>
